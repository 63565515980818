import { children, createMemo, Show, Suspense, useContext } from 'solid-js';
import { Navigate } from '@solidjs/router';
import { Meta, Title } from '@solidjs/meta';
import { ActivityIndicator, Container, Heading, Picture, Section } from '@troon/ui';
import dayjs from '@troon/dayjs';
import { clientOnly } from '@solidjs/start';
import { FacilityCtx } from '../../../../providers/facility';
import { getSchemaString } from '../../../../modules/schema/schema';
import { breadcrumbs } from '../../../../modules/schema/breadcrumb-list';
import { image } from '../../../../modules/schema/image';
import { webpage } from '../../../../modules/schema/webpage';
import { createSearchStore } from '../../../../modules/search-store';
import { timeFrameNameFromHours } from '../../../../modules/date-formatting';
import { TeeTimeSearchHeader } from '../../../../components/tee-time-search/header';
import { TeeTimeFilters, TeeTimeFiltersFAB } from '../../../../components/tee-time-search/filters';
import { Grid, GridThird, GridTwoThirds } from '../../../../components/layouts/grid';
import { ClosureMessage } from '../../../../components/closure-message';
import { schema } from '../../../../components/tee-time-search/schema';
import { useUtmParams } from '../../../../providers/utm';
import { FacilityHeaderButtons } from '../../../../components/facility/header-buttons';
import { TeeTimeAlertCallout } from '../../../../components/tee-time-search/tee-time-alert-callout';
import { FacilityBrand } from '../../../../graphql';
import { useUser } from '../../../../providers/user';
import type { RouteDefinition } from '@solidjs/router';

const TeeTimeResults = clientOnly(async () => ({
	default: (await import('../../../../components/tee-time-search/results')).TeeTimeResults,
}));

export default function ReserveTeeTime() {
	const data = useContext(FacilityCtx);
	const utm = useUtmParams();
	const user = useUser();
	const [filters, setFilters] = createSearchStore(schema);

	const address = createMemo(() => {
		const address = data()?.facility.metadata?.address;
		return address ? [address.street, address.city, [address.state, address.postalCode].join(' ')].join(', ') : null;
	});

	const supportsAccess = createMemo(
		() =>
			data()?.facility.supportsTroonAccess &&
			(!!user()?.activeTroonCardSubscription ||
				!(
					data()?.facility.supportsTroonAccessCourseSiteUpsell !== true &&
					utm().campaign === 'course-booking-link' &&
					utm().source === data()?.facility.slug
				)),
	);

	const teeTimes = children(() => (
		<Show when={data()?.facility}>
			{(facility) => (
				<Suspense
					fallback={
						<div class="min-h-dvh">
							<ActivityIndicator />
						</div>
					}
				>
					<TeeTimeResults
						filterByCourse
						facilities={[facility()]}
						filters={filters}
						setFilters={setFilters}
						singleFacility
						supportsAccess={supportsAccess()}
					/>
				</Suspense>
			)}
		</Show>
	));

	return (
		<Suspense>
			<Show when={data()?.facility}>
				{(facility) => (
					<Show when={facility().isBookable} fallback={<Navigate href={`/course/${facility().slug}`} />}>
						<Title>{`${facility()?.name} | Book tee times | Troon`}</Title>
						<Meta
							name="description"
							content={`Reserve golf tee times at ${facility()?.name} and earn Troon Rewards points.`}
						/>
						<script
							type="application/ld+json"
							innerText={getSchemaString([
								breadcrumbs(`/course/${facility().slug}/reserve-tee-time`, [
									{ name: 'Home', pathname: '/' },
									{ name: 'Courses', pathname: '/courses' },
									{ name: facility().name ?? '', pathname: `/course/${facility().slug}` },
									{ name: 'Reserve tee times', pathname: `/course/${facility().slug}/reserve-tee-time` },
								]),
								image(facility().metadata!.hero!.url ?? ''),
								webpage(`/course/${facility().slug}`, {
									title: `${facility.name} | Reserve tee times | Troon Rewards | Book tee times`,
								}),
							])}
						/>

						<div class="-mb-6 grow bg-neutral-100 md:-mb-12">
							<div class="bg-white">
								<Container class="flex-row flex-wrap items-center gap-3 pt-6 md:gap-8">
									<div class="order-1 flex aspect-square shrink-0 grow-0 basis-20 rounded-md border border-neutral bg-white p-2 md:basis-24 md:p-2">
										<Picture
											preload
											src={facility().metadata?.logo?.url}
											width={200}
											height={200}
											sizes="(min-width: 768px) 8rem, 4rem"
											mode="contain"
											class="w-full max-w-max rounded object-cover"
										/>
									</div>
									<div class="order-3 flex shrink-0 grow basis-2/3 flex-col justify-around sm:order-2 sm:basis-1/2">
										<Heading as="h1" size="h4">
											{facility().name}
										</Heading>
										<p class="order-first text-sm uppercase tracking-widest text-neutral-800">
											<Show when={facility().brand === FacilityBrand.TroonPartner}>Troon Partner Course</Show>
										</p>
										<address class="text-sm not-italic text-neutral-800" aria-label={`Address for ${facility.name}`}>
											{address()}
										</address>
									</div>
									<div class="order-2 flex grow justify-end gap-1 text-neutral-800 sm:order-3">
										<FacilityHeaderButtons facility={facility()} />
									</div>
								</Container>
							</div>

							<TeeTimeSearchHeader
								trackQuery={false}
								hideQueryInput
								filters={filters}
								setFilters={setFilters}
								scrollHeader={
									<div class="flex gap-2 @container">
										<div class="hidden aspect-square shrink-0 grow-0 basis-16 rounded-md border border-neutral bg-white p-1 @md:flex">
											<Picture
												preload
												src={facility().metadata?.logo?.url}
												width={200}
												height={200}
												sizes="(min-width: 768px) 8rem, 4rem"
												mode="contain"
												class="w-full max-w-max rounded object-cover"
											/>
										</div>
										<div class="flex grow flex-col justify-center truncate">
											<Heading as="p" size="h4" class="truncate">
												{facility().name}
											</Heading>
											<p class="truncate text-sm text-neutral-800">
												{dayjs(filters.date).format('ddd, MMM D')} • {filters.players} player
												{filters.players > 1 ? 's' : ''} •{' '}
												{timeFrameNameFromHours(filters.startAt, filters.endAt, true)}
											</p>
										</div>
									</div>
								}
							/>

							<Container>
								<Section class="pt-4 lg:pt-8">
									<ClosureMessage
										notes={data()?.notes}
										closures={data()?.closures}
										date={filters.date}
										timezone={facility().timezone}
									/>
									<Show when={facility().supportsTroonAccess || facility().courses?.length > 1} fallback={teeTimes()}>
										<Grid class="gap-y-0">
											<GridThird>
												<div
													// Manual top calculations are depend on the height of the TeeTimeSearchFields section
													// eslint-disable-next-line tailwindcss/no-arbitrary-value
													class="sticky top-[13.5rem] hidden flex-col gap-y-8 pb-8 lg:flex xl:top-[8.5rem]"
												>
													<div
														// Manual top calculations are depend on the height of the TeeTimeSearchFields section
														// eslint-disable-next-line tailwindcss/no-arbitrary-value
														class="flex max-h-[calc(100dvh-15rem)] flex-col gap-6 overflow-auto rounded border border-neutral bg-white p-6 xl:max-h-[calc(100dvh-11rem)]"
													>
														<Heading as="h2" size="h5">
															Filter by
														</Heading>

														<TeeTimeFilters
															courses={facility().courses}
															filters={filters}
															setFilters={setFilters}
															withAccessPlusDeals={supportsAccess()}
														/>
													</div>

													<Show when={facility().courses.some((c) => c.supportsTeeTimeAlerts)}>
														<TeeTimeAlertCallout facilities={[facility()]} />
													</Show>
												</div>
											</GridThird>
											<GridTwoThirds>
												{teeTimes()}
												<Show when={facility().courses.some((c) => c.supportsTeeTimeAlerts)}>
													<div class="lg:hidden">
														<TeeTimeAlertCallout facilities={[facility()]} />
													</div>
												</Show>
											</GridTwoThirds>
										</Grid>
										<div class="lg:hidden">
											<TeeTimeFiltersFAB
												courses={facility().courses}
												filters={filters}
												setFilters={setFilters}
												withAccessPlusDeals={supportsAccess()}
											/>
										</div>
									</Show>
								</Section>
							</Container>
						</div>
					</Show>
				)}
			</Show>
		</Suspense>
	);
}

export const route = { info: { nav: { sticky: false } } } satisfies RouteDefinition;
